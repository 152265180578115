import React, {useRef, useState} from 'react';
import {
    Accordion, AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Button,
    Container,
    createTheme,
    Grid,
    styled,
    ThemeProvider
} from '@mui/material';
import MainAppBar from "./components/MainAppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Placeholder from './resources/placeholder.jpg';
import {BarChart} from "@mui/x-charts";
import { CirclePicker } from 'react-color';
import {purple} from "@mui/material/colors";

import QR from './resources/qr-code.png';
import classic from './resources/classic.png';
import classyrounded from './resources/classy-rounded.png';
import dots from './resources/dots.png';
import extraroundedcorner from './resources/extra-round-corner.png';
import roundcorner from './resources/round-corner.png';
import rounded from './resources/rounded.png';
import squarecorner from './resources/square-corner.png';


const themeLight = createTheme({
    palette: {
        mode: 'light',
        background: {
            default: "#eeedeb"
        },
        text: {
            primary: "#000000"
        },
        primary: {
            main: '##fffdf8',
        },
    },
});
const themeDark = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: "#222222",
        },
        text: {
            primary: "#ffffff"
        },
        primary: {
            main: '#1c1c1c'
        }
    },

});

const SiteButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#000000',
    borderRadius: '35px',
    height: '60px',
    width: '175px',
    '&:hover': {
        backgroundColor: '#474747',
    },
}));

const ToggleButton = ({ onClick }) => {
    const [toggled, setToggled] = useState(false);

    const handleToggle = () => {
        setToggled(!toggled);
        onClick && onClick(!toggled);
    };

    return (
        <Button
            variant="contained"
            sx={{
                backgroundColor: toggled ? '#e81717' : '#7bb872',
                borderRadius: '35px',
                height: '60px',
                width: '175px',
                color: '#000',
                '&:hover': {
                    backgroundColor: toggled ? '#e81717'  : '#79b670',
                },
            }}
            onClick={handleToggle}
        >
            {toggled ? 'Disabled' : 'Enabled'}
        </Button>
    );
};

function CodeManagement() {
    const [light, setLight] = React.useState(true);

    const handleToggleButtonClick = (toggled) => {
        // Do something with the toggle state
        console.log("Toggle state:", toggled);
    };

    return (
        <ThemeProvider theme={light ? themeLight : themeDark}>
            <CssBaseline />
            <MainAppBar />
            <Container maxWidth="sm2" style={{ backgroundColor: 'whitesmoke', borderRadius: '20px', width: '95%' }}>
                <Grid container spacing={1} style={{ paddingTop: '5%', paddingBottom: '5%', marginTop: '5%' }}>
                    <Grid xs>
                        <h3 style={{ textAlign: 'center' }}>Your Code</h3>
                        <Box display="flex" justifyContent="center">
                            <Box
                                display="flex"
                                justifyContent="center"
                                component="img"
                                sx={{
                                    height: 500,
                                    width: 'auto',
                                    maxHeight: { xs: 333, md: 267 },
                                    maxWidth: { xs: 450, md: 350 },
                                }}
                                src={QR}
                            />
                        </Box>
                        <Box display="flex" justifyContent="center" style={{ marginTop: '5%' }}>
                            <ToggleButton onClick={handleToggleButtonClick} />
                        </Box>
                    </Grid>
                    <Grid xs>
                        <h3 style={{ textAlign: 'center' }}>Assigned Media</h3>
                        <Box display="flex" justifyContent="center">
                            <Box
                                display="flex"
                                justifyContent="center"
                                component="img"
                                sx={{
                                    height: 'auto',
                                    width: 'auto',
                                    maxHeight: { xs: 333, md: 267 },
                                    maxWidth: { xs: 450, md: 350 },
                                }}
                                src={Placeholder}
                            />
                        </Box>
                        <Box display="flex" justifyContent="center" style={{marginTop: '5%'}}>
                            <Button
                                variant="contained"
                                component="label"
                                sx={{
                                    backgroundColor: '#ffffff',
                                    borderRadius: '25px',
                                    width: '200px',
                                    height: '50px'

                                }}
                            >
                                Select Media
                                <input
                                    type="file"
                                    hidden
                                />
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>


            <h2 style={{ textAlign: 'center' }}>Code Customization</h2>
            <Container maxWidth={{ xs: 'sm', sm: 'md' }} style={{}}>
                <Grid container spacing={1} style={{ paddingBottom: '5%' }}>
                    <Grid item xs={12} sm={12} md={4}>
                        <h3 style={{ textAlign: 'center' }}>Your Code</h3>
                        <Box display="flex" justifyContent="center">
                            <Box
                                display="flex"
                                justifyContent="center"
                                component="img"
                                sx={{
                                    height: 500,
                                    width: 'auto',
                                    maxHeight: { xs: 333, md: 267 },
                                    maxWidth: { xs: 450, md: 350 },
                                }}
                                src={QR}
                            />
                        </Box>
                        <Box display="flex" justifyContent="center" style={{ marginTop: '5%' }}>
                            <SiteButton>Update</SiteButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <h3 style={{ textAlign: 'center' }}>Color Options</h3>
                        <h4 style={{ textAlign: 'center '}}>Foreground</h4>
                        <Box display="flex" justifyContent="center">
                            <Box display="flex" justifyContent="center">
                                <CirclePicker></CirclePicker>
                            </Box>
                        </Box>
                        <h4 style={{ textAlign: 'center '}}>Background</h4>
                        <Box display="flex" justifyContent="center">
                            <Box display="flex" justifyContent="center">
                                <CirclePicker></CirclePicker>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <h3 style={{ textAlign: 'center' }}>Code Customisation</h3>
                        <Grid container spacing={1} style={{ paddingBottom: '5%' }}>
                            <Grid item xs={12} sm={12} md={4}>
                                <h3 style={{ textAlign: 'center' }}>Test 1</h3>

                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <h3 style={{ textAlign: 'center' }}>Test 2</h3>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <h3 style={{ textAlign: 'center' }}>Test 3</h3>
                            </Grid><Grid item xs={12} sm={12} md={4}>
                            <h3 style={{ textAlign: 'center' }}>Test 4</h3>

                        </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <h3 style={{ textAlign: 'center' }}>Test 5</h3>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <h3 style={{ textAlign: 'center' }}>Test 6</h3>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>


            <Container maxWidth="sm2" style={{ backgroundColor: 'whitesmoke', borderRadius: '20px', width: '95%'}}>
                <Container style={{paddingTop: '5%' , paddingBottom: '5%'}}>
                    <BarChart
                        series={[
                            { data: [35, 44, 24, 34] },
                            { data: [51, 6, 49, 30] },
                            { data: [15, 25, 30, 50] },
                            { data: [60, 50, 15, 25] },
                        ]}
                        height={290}
                        xAxis={[{ data: ['Q1', 'Q2', 'Q3', 'Q4'], scaleType: 'band' }]}
                        margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                    />
                </Container>
            </Container>

        </ThemeProvider>
    );
}

export default CodeManagement;
