import React from 'react';
import {Button, Container, createTheme, Grid, Link, Paper, styled, ThemeProvider} from '@mui/material'
import MainAppBar from "./components/MainAppBar";
import CssBaseline from "@mui/material/CssBaseline";
import { TypeAnimation } from 'react-type-animation';
import Box from "@mui/material/Box";
import {purple} from "@mui/material/colors";
import Login from './Login'
import { useHistory } from 'react-router-dom';
import Marquee from "react-fast-marquee";
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

const themeLight = createTheme({
    palette: {
        mode: 'light',
        background: {
            default: "#eeedeb"
        },
        text: {
            primary: "#000000"
        },
        primary: {
            main: '##fffdf8',
        },
    },
});
const themeDark = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: "#222222",
        },
        text: {
            primary: "#ffffff"
        },
        primary: {
            main: '#1c1c1c'
        }
    },

});

const SiteButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#000000',
    borderRadius: '35px',
    height: '60px',
    width: '175px',
    '&:hover': {
        backgroundColor: '#474747',
    },
}));



function Home () {
    const [light, setLight] = React.useState(true);
    const navigate = useNavigate(); // Use useNavigate instead of useHistory
    const handleButtonClick = () => {
        // Navigate to the "/home/yourLogin" route
        navigate('/login');
    };

    return (
        <ThemeProvider theme={light ? themeLight : themeDark}>
            <CssBaseline />
            <MainAppBar></MainAppBar>
            <Container maxWidth="sm2">
                <Grid container spacing={{xs: 3}} style={{ paddingTop: '5%'}}>
                    <Grid xs>

                    </Grid>
                    <Grid xs={6}>
                        <TypeAnimation
                            sequence={[
                                // Same substring at the start will only be typed out once, initially
                                'Qmedia Solutions',
                            ]}
                            wrapper="span"
                            speed={20}
                            style={{ fontSize: '2em', display: 'block', textAlign: 'center', fontWeight: 'bold'}}
                            cursor={false}
                        />
                        <p style={{textAlign: 'center', fontSize: '1.25em'}}>Share your content your way.</p>
                        <Box textAlign='center' style={{marginTop: '5%'}}>
                            <Link to={Login}>
                                <SiteButton>Learn More</SiteButton>
                            </Link>
                            </Box>
                    </Grid>
                    <Grid xs>

                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth={{ xs: 'sm', sm: 'md' }} style={{ backgroundColor: 'whitesmoke' , borderRadius: '20px' , width: '95%'}}>
                <Grid container spacing={1} style={{ paddingTop: '5%' , paddingBottom: '5%' , marginTop: '5%'}}>
                    <Grid item xs={12} sm={12} md={4}>
                        <h3 style={{textAlign: 'center'}}>What we do</h3>
                        <p style={{ marginBottom: '20px' , marginRight: '20px' , marginLeft: '20px' }}> "Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                            quae ab illo inventore veritatis et quasi architecto beatae vitae
                            dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                            aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
                            eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam
                            est, qui dolorem ipsum quia"</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <h3 style={{textAlign: 'center'}}>How we can help</h3>
                        <p style={{ marginBottom: '20px' , marginRight: '20px' , marginLeft: '20px' }}> "Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                            quae ab illo inventore veritatis et quasi architecto beatae vitae
                            dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                            aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
                            eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam
                            est, qui dolorem ipsum quia"</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <h3 style={{textAlign: 'center'}}>How we fit in</h3>
                        <p style={{ marginBottom: '20px' , marginRight: '20px' , marginLeft: '20px' }}> "Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                            quae ab illo inventore veritatis et quasi architecto beatae vitae
                            dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                            aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
                            eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam
                            est, qui dolorem ipsum quia"</p>
                    </Grid>
                </Grid>
            </Container>

        </ThemeProvider>
    );
}

export default Home;
