import React, { useState } from 'react';
import {useHistory, useNavigate} from 'react-router-dom'; // Import useHistory hook
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import {
    Button,
    Container,
    createTheme,
    inputLabelClasses, styled,
    TextField,
    ThemeProvider
} from '@mui/material'
import LoginAppBar from "./components/LoginAppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { purple } from "@mui/material/colors";

const themeLight = createTheme({
    palette: {
        mode: 'light',
        background: {
            default: "#eeedeb"
        },
        text: {
            primary: "#000000"
        },
        primary: {
            main: '##fffdf8',
        },
    },
});
const themeDark = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: "#222222",
        },
        text: {
            primary: "#ffffff"
        },
        primary: {
            main: '#1c1c1c'
        }
    },

});

const LoginButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#000000',
    borderRadius: '35px',
    height: '40px',
    width: '185px',
    '&:hover': {
        backgroundColor: '#474747',
    },
}));

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const auth = getAuth(); // Initialize authentication instance

    const handleUsernameInput = (event) => {
        setEmail(event.target.value);
    }

    const handlePasswordInput = (event) => {
        setPassword(event.target.value);
    }

    const navigate = useNavigate();
    const handleSignUp = () => {
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed up
                const user = userCredential.user;
                alert("Account Successfully Created!")
                navigate('/login');

                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert("Account Creation Failed!")
                // ..
            });
    };

    return (
        <ThemeProvider theme={themeLight}>
            <CssBaseline />
            <LoginAppBar />
            <Container maxWidth="sm2">
                <Container maxWidth="sm2">
                    <Box display="flex" justifyContent="center">
                        <Box style={{
                            backgroundColor: 'whitesmoke',
                            borderRadius: '20px',
                            marginTop: '20%',
                            width: '600px',
                        }}>
                            <p style={{textAlign: 'center', fontWeight: 'bold', paddingTop: '5%'}}>Create a QMS account</p>
                            <p style={{textAlign: 'center'}}>One account for all your sharing needs.</p>
                            <Box display="flex" justifyContent="center">
                                <TextField
                                    label={"Email"}
                                    color={"primary"}
                                    onChange={handleUsernameInput}
                                    InputLabelProps={{
                                        sx: {
                                            color: "black",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "black"
                                            }
                                        }}}
                                    style={{
                                        paddingRight: '5%',
                                        width: '50%',
                                    }}
                                />
                            </Box>
                            <Box display="flex" justifyContent="center" style={{marginTop: '2.5%', marginBottom: '2.5%'}}>
                                <TextField
                                    label={"Phone"}
                                    color={"primary"}
                                    type="email"
                                    onChange={handlePasswordInput}
                                    InputLabelProps={{
                                        sx: {
                                            color: "black",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "black"
                                            },
                                        }}}
                                    style={{
                                        paddingRight: '5%',
                                        width: '50%',

                                    }}/>
                            </Box>
                            <Box display="flex" justifyContent="center" style={{marginTop: '2.5%', marginBottom: '2.5%'}}>
                                <TextField
                                    label={"Password"}
                                    color={"primary"}
                                    type="password"
                                    onChange={handlePasswordInput}
                                    InputLabelProps={{
                                        sx: {
                                            color: "black",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "black"
                                            },
                                        }}}
                                    style={{
                                        paddingRight: '5%',
                                        width: '50%',

                                    }}/>
                            </Box>
                            <Box display="flex" justifyContent="center" style={{marginTop: '5%', marginBottom: '5%'}}>
                                <LoginButton onClick={handleSignUp} style={{backgroundColor: 'black'}}>Register</LoginButton>
                            </Box>
                            <Box display="flex" justifyContent="center" style={{marginTop: '5%', marginBottom: '5%'}}>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Container>
        </ThemeProvider>
    );
}

export default Login;
