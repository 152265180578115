import React from 'react';

import Home from './Home'
import Login from './Login'
import CodeManagement from './CodeManagement'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import Register from "./Register";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/codemanagement",
    element: <CodeManagement />,
  },
  {
    path: "/register",
    element: <Register />,
  },
]);

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA_1QKGSoeb-D9Ue9lh38Za4vqUE11Hu-s",
  authDomain: "qmedia-main.firebaseapp.com",
  databaseURL: "https://qmedia-main.firebaseio.com",
  projectId: "qmedia-main",
  storageBucket: "qmedia-main.appspot.com",
  messagingSenderId: "237438374040",
  appId: "1:237438374040:web:fe541eca7e54f057b240ed"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const App = () => {
  return (
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
  );
}

export default App;
