import React, { useState } from 'react';
import {useHistory, useNavigate} from 'react-router-dom'; // Import useHistory hook
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {
    Button,
    Container,
    createTheme,
    inputLabelClasses, Link, styled,
    TextField,
    ThemeProvider
} from '@mui/material'
import LoginAppBar from "./components/LoginAppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { purple } from "@mui/material/colors";

const themeLight = createTheme({
    palette: {
        mode: 'light',
        background: {
            default: "#eeedeb"
        },
        text: {
            primary: "#000000"
        },
        primary: {
            main: '##fffdf8',
        },
    },
});
const themeDark = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: "#222222",
        },
        text: {
            primary: "#ffffff"
        },
        primary: {
            main: '#1c1c1c'
        }
    },

});

const LoginButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#000000',
    borderRadius: '35px',
    height: '40px',
    width: '185px',
    '&:hover': {
        backgroundColor: '#474747',
    },
}));

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const auth = getAuth(); // Initialize authentication instance

    const handleUsernameInput = (event) => {
        setEmail(event.target.value);
    }

    const handlePasswordInput = (event) => {
        setPassword(event.target.value);
    }

    const navigate = useNavigate();
    const handleLogin = () => {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                // Handle successful login
                navigate('/codemanagement');

            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert("Failed!")

                // Handle errors
            });
    };

    return (
        <ThemeProvider theme={themeLight}>
            <CssBaseline />
            <LoginAppBar />
            <Container maxWidth="sm2">
                <Container maxWidth="sm2">
                    <Box display="flex" justifyContent="center">
                        <Box style={{
                            backgroundColor: 'whitesmoke',
                            borderRadius: '20px',
                            marginTop: '20%',
                            width: '400px',
                        }}>
                            <p style={{textAlign: 'center', fontWeight: 'bold', paddingTop: '5%'}}>Login</p>
                            <Box display="flex" justifyContent="center">
                                <TextField
                                    label={"Username"}
                                    color={"primary"}
                                    onChange={handleUsernameInput}
                                    InputLabelProps={{
                                        sx: {
                                            color: "black",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "black"
                                            }}}}
                                />
                            </Box>
                            <Box display="flex" justifyContent="center" style={{marginTop: '5%', marginBottom: '5%'}}>
                                <TextField
                                    label={"Password"}
                                    color={"primary"}
                                    type="password"
                                    onChange={handlePasswordInput}
                                    InputLabelProps={{
                                        sx: {
                                            color: "black",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "black"
                                            },
                                        }}}/>
                            </Box>
                            <Box display="flex" justifyContent="center" style={{marginTop: '5%', marginBottom: '5%'}}>
                                <LoginButton onClick={handleLogin} style={{backgroundColor: 'black'}}>Login</LoginButton>
                            </Box>
                            <Box display="flex" justifyContent="center" style={{marginTop: '5%', marginBottom: '5%'}}>
                            </Box>
                            <div
                                style={{ textAlign: 'center', paddingBottom: '5%', cursor: 'pointer' }}
                                onClick={() => navigate('/register')}
                            >
                                <p style={{ textDecoration: 'underline', margin: 0 }}>Create Account</p>
                            </div>
                        </Box>
                    </Box>
                </Container>
            </Container>
        </ThemeProvider>
    );
}

export default Login;
